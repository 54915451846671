.page {
  background: #12263b;
  background: linear-gradient(
    45deg,
    rgba(18, 38, 58, 1) 0%,
    rgba(28, 66, 105, 1) 100%
  );

  height: 100%;
  width: 100%;
  padding: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.content {
  margin-bottom: 48px;
  width: 70rem;
  margin: 0 auto;
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  line-height: 1.2;
  color: white;
  letter-spacing: -1px;
  font-family: "Poppins";
  margin-bottom: 24px;
}

.logo {
  height: 36px;
  margin-bottom: 24px;
}

.subtext {
  color: white;
  font-size: 24px;
  margin-bottom: 40px;
}

.loginButton {
  padding: 16px 48px;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 0;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  color: rgb(18, 38, 58);
  transition: background-color 200ms ease-in-out;
}

.loginButton:hover {
  background-color: rgb(195, 211, 226);
}

.error {
  color: #ff7179;
  margin-bottom: 32px;
  font-weight: 500;
}

:root {
  --color-primary-hex: #00a4cd;
  --color-primary-rgb: 0, 164, 205;
  --color-primary: rgb(var(--color-primary-rgb));
  --color-black: #424242;
  --color-destructive: #d53a4f;
  --default-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px;
  --accent-faint: #e2e6ec;
  --color-background: #eef2f8;
}

.loader {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.content {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animationGroup {
  animation: rotate 1.6s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}
.logoIcon {
  overflow: visible;
  opacity: 1;
}

.loadingText {
  margin-top: 16px;
  font-size: 18px;
  font-style: italic;
  color: grey;
}

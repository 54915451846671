.subtitle {
  line-height: 1.5;
  margin-bottom: 24px;
}
.pageContent {
  max-width: 60rem;
}

.submitButton {
  border-radius: 7px;
  background-color: var(--color-primary);
  color: white;
  font-size: 13px;
  border: 0;
  padding: 14px 32px;
  font-weight: bold;
  font-family: inter;
  text-transform: uppercase;
  margin-top: 32px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
}

.submitButton:hover {
  background-color: #32b6d7;
}

.actions {
  display: flex;
  gap: 12px;
}

.cancelButton {
  border-radius: 7px;
  background-color: white;
  color: var(--color-primary);
  font-size: 13px;
  border: 1px solid var(--color-primary);
  padding: 12px 32px;
  font-weight: bold;
  font-family: inter;
  text-transform: uppercase;
  margin-top: 32px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
}

.cancelButton:hover {
  background-color: var(--accent-faint);
}

.table {
  box-shadow: var(--default-box-shadow);
  border-radius: 10px;
  border-spacing: 0;
  background-color: white;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--accent-faint);
  border-collapse: collapse; /* Ensures cells share borders */
}

.table th {
  text-align: start;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  color: #757575;
  border-bottom: 1px solid var(--color-background);
  padding: 12px 12px;
  background-color: #fafafa;
}

.table td {
  border: 1px solid var(--color-background); /* Solid black border for all cells */
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}

.table td:not(.noPadding) {
  padding: 10px 12px;
}

.table tr:not(:last-child) td {
  border-bottom: 1px solid #eeeeee;
}

.table td:last-child,
.table th:last-child {
  border-right: none;
}

.table tr:last-child td:first-child {
  border-bottom-left-radius: 7px;
}

.table tr:last-child td:last-child {
  border-bottom-right-radius: 7px;
}

.warningIcon {
  width: 24px;
  height: 24px;
}

.warningBox,
.checkBoxCell {
  text-align: center;
}

.warningBox {
  text-align: center;
}

.warningBox button {
  border: 0;
  background: 0;
  padding: 0;
  margin: 0 auto;
  cursor: pointer;
}

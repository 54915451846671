.loadState,
.failState,
.emptyState,
.noResults {
  flex: 1;
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.failState,
.emptyState {
  gap: 8px;
}

.emptyStateTitle {
  font-weight: 600;
  font-size: 32px;
  margin: 0;
}

.emptyStateSubtitle {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 16px;
  color: #757575;
}

.noResults {
  font-size: 24px;
  font-weight: 500;
}

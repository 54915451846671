.sidebar {
  position: sticky;
  position: -webkit-sticky;
  background: #272838;
  top: 0;
  bottom: 0;
  align-self: flex-start;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 230px;
}

.logo {
  height: 30px;
  padding: 24px;
  box-sizing: content-box;
}

.profileIcon {
  width: 27px;
  height: 27px;
}

.footer {
  margin: 24px;
  padding: 12px 0;
  display: flex;
  gap: 12px;
  align-items: center;
  line-height: 1.3;
  border-top: 2px solid #3c3d56;
  justify-self: flex-end;
}

.footerName {
  font-weight: 600;
  color: white;
}

.footer a {
  font-weight: 400;
  font-size: 14px;
  color: #cbd7d8;
}

.amountInCart {
  padding: 1px 10px;
  border-radius: 500px;
  background-color: white;
  color: #272838;
  font-size: 12px;
  font-weight: 600;
}

.footerOrg {
  font-weight: 400;
  color: white;
  font-size: 13px;
  opacity: 0.75;
}

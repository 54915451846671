.search {
  display: flex;
  border-radius: 7px;
  border: 1px solid var(--accent-faint);
  background: white;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  box-sizing: border-box;
  box-shadow: var(--default-box-shadow);
}

.search:has(.searchInput:focus) {
  border-color: transparent;
  outline: 2px solid var(--color-primary);
}

.searchIcon {
  width: 20px;
  height: 20px;
}

.searchInput {
  font-size: 16px;
  border: 0;
  background: 0;
  padding: 12px;
  flex: 1;
  height: 100%;
  width: 100%;
}

.searchInput:focus {
  outline: 0;
}

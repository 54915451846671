.subtitle {
  line-height: 1.5;
  margin-bottom: 24px;
}

.pageContent {
  max-width: 60rem;
}

.panel {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: var(--default-box-shadow);
  border-radius: 15px;
  border: 1px solid var(--accent-faint);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  max-width: 50rem;
  height: 375px;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.loadingDescription {
  font-style: italic;
}

.fileUploadText {
  font-size: 22px;
  margin-bottom: 24px;
  font-weight: 500;
}

.uploadButton {
  border-radius: 10px;
  background-color: var(--color-primary);
  color: white;
  font-size: 14px;
  border: 0;
  padding: 16px 48px;
  font-weight: bold;
  font-family: inter;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
}

.uploadButton:hover {
  background-color: #32b6d7;
}

.activeUplaod {
  background-color: lightblue;
}

.uploadIcon {
  width: 64px;
  margin-bottom: 24px;
}

.fileUploadContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.dropzone {
  width: 100%;
  border: 2px dashed var(--accent-faint);
  height: 100%;
  border-radius: 10px;
  transition: border 150ms ease-in-out, background-color 150ms ease-in-out;
  animation: dash 1s linear infinite;
}

.dropzone.active {
  border: 2px dashed var(--color-primary);
  background-color: #f3f4f7;
}

.acceptTypes {
  font-size: 14px;
  color: grey;
  margin-top: 16px;
  font-style: italic;
}

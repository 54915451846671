.orderHistoryItem {
  padding: 24px 32px;
  margin-bottom: 16px;
  border: 1px solid var(--accent-faint);
}

.header {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
}

.trackingNumber {
  font-size: 14px;
  color: #616161;
  line-height: 1.5;
}

.label {
  line-height: 1.5;
  margin-bottom: 4px;
  color: #616161;
  font-size: 14px;
  font-weight: 500;
}

.value {
  line-height: 1.3;
  font-size: 14px;
}

.data {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  border-top: 2px solid #eeeeee;
  padding-top: 16px;
}

.actionRequired {
  width: 100%;
  background: #fff8e1;
  padding: 16px;
  border: 2px solid #ffe082;
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 24px;
  display: flex;
  gap: 32px;
  align-items: center;
}

.actionRequiredDescription {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 8px;
}

.actionRequired ul {
  margin: 0;
  padding-left: 8px;
  list-style-position: outside;
}
.actionRequired li {
  font-size: 14px;
  line-height: 1.5;
  margin-left: 8px;
}

.actionRequiredIcon {
  width: 40px;
  height: 40px;
  padding-left: 8px;
}

.requiredPrescription {
  font-weight: 500;
}

.sendLink {
  background: 0;
  border: 0;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: var(--color-primary);
  text-decoration: underline;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-left: 4px;
  cursor: pointer;
}

.sendLink:active {
  opacity: 0.8;
}

.refill {
  margin-bottom: 4px;
  color: #757575;
  font-size: 14px;
}

.orderItem {
  line-height: 1.3;
}

.orderItems {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
}

.cancel {
  border: 1px solid var(--color-destructive);
  background: white;
  color: var(--color-destructive);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.cancel:active {
  background: #fafafa;
}

.dataPoint {
  flex: 1;
}

.body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: var(--color-background);
  flex: 1;
}

.submitSuccessBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.submitSuccessBox h1 {
  font-size: 32px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin: 0;
  margin-bottom: 16px;
}

.subtext {
  margin-bottom: 32px;
  text-align: center;
  margin-top: 0;
  line-height: 1.5;
  font-size: 16px;
}

.subtext p {
  color: #616161;
  margin: 0;
  line-height: 1.5;
  margin-bottom: 32px;
}

.check {
  height: 80px;
  width: 80px;
}

.patientDeleteConfirmation {
  text-align: center;
  padding: 32px;
  box-sizing: border-box;
  width: 500px;
}

.textWrap {
  margin-bottom: 24px;
}

.patientDeleteConfirmation h3 {
  line-height: 1.3;
  margin-bottom: 8px;
  font-size: 20px;
}

.patientDeleteConfirmation p {
  line-height: 1.5;
}

.patientDeleteConfirmation .deleteActions {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  justify-content: center;
}

.deletionError {
  color: var(--color-destructive);
}

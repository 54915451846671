.sidebarNavItem {
  padding: 12px 24px;
  color: var(--color-black);
  background: 0;
  border: 0;
  display: block;
  width: 100%;
  text-align: start;
  font-size: 16px;
  color: #cbd7d8;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebarNavItem:hover {
  cursor: pointer;
}

.sidebarNavItem:hover {
  background: #464865;
}

.active {
  background: #464865;
  color: white;
  font-weight: 600;
}

.supportInput {
  padding: 18px;
  border: 0;
  border-radius: 10px;
  box-shadow: var(--default-box-shadow);
  font-family: "inter";
  border: 1px solid var(--accent-faint);
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
}

.inputError {
  border: 1px solid var(--color-destructive);
  outline: 1px solid var(--color-destructive);
}

.error {
  font-size: 14px;
  color: var(--color-destructive);
  margin-top: 8px;
}

textarea.supportInput {
  resize: vertical;
  min-height: 160px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
}

.titleInputWrap {
  width: 100%;
}

.buttonWrap {
  width: 100%;
  display: flex;
  margin-top: 16px;
}

.pageBody {
  max-width: 60rem;
}

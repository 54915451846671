.button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 16px 36px;
  font-weight: 500;
  font-family: "Inter";
  box-sizing: border-box;
  white-space: nowrap;
  overflow: ellipsis;
  letter-spacing: 1px;
  transition: background-color 150ms ease-in-out;
  /* box-shadow: var(--default-box-shadow); */
  position: relative;
  cursor: pointer;
}

.primary:hover {
  background-color: #00b8e6;
}

.primary {
  color: white;
  background-color: var(--color-primary);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.secondary {
  color: var(--color-primary);
  border: 1px solid var(--accent-faint);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 16px;
  background: white;
}

.secondary:hover {
  background-color: #f5f5f5;
}

.square {
  border-radius: 10px;
}

.pill {
  border-radius: 100px;
}

.fillWidth {
  width: 100%;
}

.destructive {
  color: var(--color-destructive);
}

.loaderBox {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.showLoader .children {
  visibility: hidden;
}

.showLoader .loaderBox {
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  .button {
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.plus {
  width: 24px;
  height: 24px;
}

.plusWrap {
  display: flex;
  align-items: center;
  gap: 8px;
}
